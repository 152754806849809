import { useState } from 'react';
import { Drawer, Fab, Link, Typography } from '@material-ui/core';
import styled from 'styled-components';
// @ts-ignore
import './App.css';

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

import dhdlracingleague from './assets/images/dhdlracingleague.png';
import data077 from './assets/images/data077.jpeg';
import greeny____ from './assets/images/greeny____.jpeg';
import jung_usm_veedel from './assets/images/jung_usm_veedel.jpeg';
import nlsracer from './assets/images/nlsracer.jpeg';
import peboddy from './assets/images/peboddy.jpeg';

const streamerL1 = [
  { type: 'twitch', id: 'dhdlracingleague', image: dhdlracingleague },
  { type: 'twitch', id: 'brokotv', image: peboddy },
  { type: 'twitch', id: 'data077', image: data077 },
  { type: 'twitch', id: 'GreenyBerlin', image: greeny____ },
  { type: 'twitch', id: 'jung_usm_Veedel', image: jung_usm_veedel },
  { type: 'twitch', id: 'Nlsracer', image: nlsracer },
];

export const App = () => {
  const [currentStream, setCurrentStream] = useState<{ type: string; id: string; image: string }>(streamerL1[0]);
  const [loadStream, setLoadStream] = useState<boolean>(true);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const handleStreamReload = (stream: { type: string; id: string; image: string }) => {
    setLoadStream(false);

    setTimeout(() => {
      setCurrentStream(stream);
      setLoadStream(true);
      setIsMenuOpen(false);
    }, 300);
  };

  return (
    <Container>
      <header>
        <Typography variant='h5'>DHDL RACING STREAMS</Typography>
      </header>
      <MainTabletAndHigher>
        <IFrameContainer>
          {loadStream && currentStream.type === 'twitch' && (
            <iframe
              src={`https://embed.twitch.tv?allowfullscreen=true&channel=${currentStream.id}&parent=test.dhdlracingleague.de&playsinline=true&font-size=small&height=100&layout=video-with-chat&parent=localhost&theme=dark&width=100`}
              scrolling='no'
              frameBorder='0'
              allow='autoplay; fullscreen'
              allowFullScreen
              title='Twitch'
              sandbox='allow-modals allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox'
              width='100%'
              height='100%'
            />
          )}
          {loadStream && currentStream.type === 'youtube' && (
            <Typography variant='h5'>Aktuell nicht verfügbar</Typography>
          )}
        </IFrameContainer>
      </MainTabletAndHigher>
      <MainMobile>
        <Typography variant='subtitle1'>Wartungsarbeiten...</Typography>
        <br />
        <Typography variant='body1'>Aber hier der Link zum </Typography>
        <Link variant='h4' target='_blank' rel='noopener noreferrer' href={`https://twitch.tv/${currentStream.id}`}>
          Stream
        </Link>
      </MainMobile>
      <FabContainer>
        <Fab onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <ArrowUpwardIcon />
        </Fab>
      </FabContainer>

      <Drawer anchor='bottom' open={isMenuOpen} onClose={() => setIsMenuOpen(false)}>
        <Subline>
          <Typography variant='h6' component='p'>
            Onboard Streams
          </Typography>
        </Subline>
        <Content>
          {streamerL1.map((stream) => {
            if (currentStream.id === streamerL1[0].id && stream.id === streamerL1[0].id) {
              return <></>;
            } else {
              return (
                <Streamer
                  onClick={() => handleStreamReload(stream)}
                  image={stream.image}
                  key={stream.id}
                  active={currentStream.id === stream.id}
                />
              );
            }
          })}
        </Content>
      </Drawer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 15px;

  header {
    height: 48px;
  }

  @media (min-width: 1024px) {
    padding: 25px;

    header {
      height: 56px;
    }
  }
`;

const MainTabletAndHigher = styled.main`
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
`;

const MainMobile = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 768px) {
    display: none;
  }
`;

const Content = styled.div`
  padding: 15px;
  white-space: nowrap;
  overflow-x: auto;

  @media (min-width: 768px) {
    padding: 25px;
  }
`;

const IFrameContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  height: 80vh;
  width: 100%;
  border-radius: 20px;
  border: 5px solid #bfff00;
  background-color: #bfff00;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: auto;
  }

  @media (min-width: 768px) {
    height: calc(100vh - 106px);
  }
`;

const Subline = styled.div`
  padding: 15px 15px 0;
`;

interface StreamerProps {
  image: string;
  active: boolean;
}

const Streamer = styled.button<StreamerProps>`
  display: inline-block;
  border-radius: 10px;
  height: 150px;
  width: 200px;
  background-image: ${(props) => `url(${props.image})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  border: 3px solid ${(props) => (props.active ? '#bfff00' : '000')};
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;

  &:not(:last-child) {
    margin-right: 25px;
  }

  &:hover {
    transform: scale(1.02);
  }
`;

const FabContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 10px;
  left: 0;
  right: 0;

  .MuiFab-root {
    background-color: #bfff00;

    svg {
      color: #000;
    }
  }

  @media (min-width: 768px) {
    bottom: 0;
  }
`;
